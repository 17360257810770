var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"cards",style:({ overFlowY: _vm.$vssWidth < '600' ? 'hidden' : 'scroll' })},[_c('v-row',{style:({ width: _vm.$vssWidth < '600' ? '1345px' : '100%' })},_vm._l((_vm.cards),function(item,key){return _c('v-col',{key:key,attrs:{"cols":"2","sm":"6","md":"4","lg":"2"}},[_c('v-card',{class:_vm.$vuetify.theme.dark ? 'OrgProdCardsDark' : 'OrgProdCardsLight',attrs:{"width":"100%","height":"200px","elevation":"1"}},[_c('div',{staticClass:"iconCard"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"fab":"","elevation":"0","x-small":"","color":"primary"},on:{"click":function($event){return _vm.escolher(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("TelaOrgProdutos.tabela")))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"fab":"","elevation":"0","x-small":"","color":"primary"},on:{"click":function($event){_vm.populaGrafico(item.id);
                    _vm.tituloGrafico(item.id);}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" show_chart ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("TelaOrgProdutos.grafico")))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","elevation":"0","x-small":"","color":"primary"},on:{"click":function($event){_vm.removeGrid(item.id);
                    _vm.removeGrafico(item.id);}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" cleaning_services ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("TelaOrgProdutos.limpar")))])])],1),_c('div',{staticClass:"centralized"},[_c('span',{staticClass:"spanSpace"}),_c('span',{staticClass:"numbers",style:(("" + (_vm.$vuetify.theme.dark ? 'color: #f0f0f5' : '')))},[_vm._v(_vm._s(item.total))]),_c('span',{staticClass:"spanSpace"},[_vm._v(_vm._s(item.nome))]),_c('div',{staticClass:"ultDiv"},[_c('span',{staticClass:"ultSpan"},[_vm._v(" "+_vm._s(_vm.$t("TelaOrgProdutos.ultimaMod"))+" "+_vm._s(item.dt_atusis)+" ")])])])])],1)}),1)],1),(_vm.ativaGrafico == true && _vm.ativaAvisoGrafico == false)?_c('div',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"1","width":"100%"}},[_c('apexchart',_vm._b({key:_vm.keyGraph,staticClass:"d-flex justify-center",attrs:{"width":"800","type":"pie","options":_vm.checksChart.options,"series":_vm.checksChart.series}},'apexchart',_vm.populaGrafico,false))],1)],1)],1)],1):_vm._e(),(_vm.ativaGrafico == true && _vm.ativaAvisoGrafico == true)?_c('div',{staticClass:"avisoGrafico"},[_c('v-overlay',{attrs:{"dark":""}},[_c('v-card',{attrs:{"max-width":"600"}},[_c('v-container',[_c('v-card-title',{staticStyle:{"color":"#d8000c","display":"flex","justify-content":"center"}},[_c('v-icon',{staticStyle:{"margin-right":"5px","color":"#d8000c"}},[_vm._v(" error_outline ")]),_vm._v(" "+_vm._s(_vm.$t("TabelaImportada.Dialog.aviso"))+" ")],1),_c('v-card-text',{staticStyle:{"font-weight":"bold","display":"flex","justify-content":"center"}},[_vm._v(" Não há produtos com "+_vm._s(_vm.escolhaGrafico)+" cadastrado ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-btn',{staticStyle:{"margin":"4px"},attrs:{"color":"error"},on:{"click":function($event){_vm.ativaGrafico = false;
                _vm.ativaAvisoGrafico = false;}}},[_vm._v(" "+_vm._s(_vm.$t("TabelaImportada.Dialog.fechar"))+" ")])],1)],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"titleDiv"},[_c('h2',[_vm._v(_vm._s(_vm.escolha))])]),(_vm.escolha == 'Grupos')?_c('grupos',{key:_vm.count,attrs:{"click":_vm.count}}):(_vm.escolha !== '')?_c('dep-colec-categ',{key:_vm.count,attrs:{"escolha":_vm.escolha,"click":_vm.count}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }