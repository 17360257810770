/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: script.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:03
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:43:45
 Modificado por: pedro.rosa
*/
import ferramentas from "@/components/ferramentas";
import botoesAcao from "@/components/botoesAcao";
import {
  camposEditarProduto,
  opcoesTipo,
  itensFerramentas,
  colunasExcel,
  colunas,
} from "./colunas";
import moment from "moment";
export default {
  components: { ferramentas, botoesAcao },
  prop: {
    click: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      flexgrid: {},
      opcoesTipo: opcoesTipo,
      abaEscolhida: "",
      keyTabelaWijmo: 0,
      itensFerramentas: itensFerramentas,
      colunasExcel: colunasExcel,
      colunas: colunas,
      botoes: ["EXPORTAR_EXCEL", "EXPORTAR_PDF", "EDITAR", "SALVAR", "NOVO"],
      camposEditar: [],
      dados: [],
      abas: [],
      importsObrigatorios: ["COMISSÃO", "SALÁRIO FIXO", "VENDEDOR"],
      camposEditarProduto: camposEditarProduto,
      gruposSelecionados: [],
    };
  },
  watch: {
    click() {
      this.mountObj();
    },
  },
  mounted() {
    this.mountObj();
  },
  methods: {
    initializeGrid(flexgrid) {
      this.flexgrid = flexgrid;
    },
    escolheAba(aba) {
      this.abaEscolhida = aba;
    },
    alteraVendFerramentas(ret) {
      this.dados.forEach((f) => {
        if (f.isSelected) {
          f.ce113_cd_situacex = ret.situacao == 1 ? "Ativo" : "Bloqueado";
          f.ce113_id_exibljex = ret.visiVenda == 1 ? "Sim" : "Não";
        }
      });
      this.keyTabelaWijmo++;
    },
    async mountObj() {
      try {
        this.startLoader();
        let grupo = await this.request({
          metodo: "get",
          sp: "SP_CE360901001",
          params: { _rSITUAC: 1 },
        });
        grupo = grupo.database;
        grupo = grupo.map((f) => {
          return {
            ...f,
            ce113_cd_situacex: f.ce113_cd_situac == 1 ? "Ativo" : "Bloqueado",
            ce113_id_exibljex: f.ce113_id_exiblj == 1 ? "Sim" : "Não",
            isSelected: false,
          };
        });
        this.dados = this.recursive(grupo);
        const objAba = {
          nome_aba: `${this.$t("TelaLojas.pesquisa")} ${this.abas.length +
            1} - ${moment()
            .locale("pt-br")
            .format("l")} - ${this.$t("TelaLojas.quantidade")}: ${
            this.dados.length
          }`,
          pesquisa: this.dados,
          remove: false,
        };
        this.abas.push(objAba);
      } catch {
        this.notification().error({erro: "Erro ao trazer o produto"});
      } finally {
        this.stopLoader();
      }
    },
    async salvaTabela() {
      let usuario = await this.getUser();
      this.dados.forEach((e) => {
        if (e.isSelected) {
          let obj = {
            _rNR_NOVO: 2,
            _rCD_GRP: e.ce113_cd_grp,
            _rDS_HIER: e.ce113_ds_grpex,
            _rNR_NIVEL: e.ce113_nr_nivel,
            _rCD_GRPSUP: e.ce113_cd_grpsup == "" ? null : e.ce113_cd_grpsup,
            _rDS_GRP: e.ce113_ds_grp,
            _rCD_SITUAC: e.ce113_cd_situacex == "Ativo" ? 1 : 2,
            _rID_EXIBLJ: e.ce113_id_exiblj == "Sim" ? 1 : 2,
            _rCD_NCM: e.ce113_cd_nps == "" ? null : e.ce113_cd_nps,
            _rCD_EXNCM: e.ce113_cd_exncm == "" ? null : e.ce113_cd_exncm,
            _rCD_CLASS: null,
            _rCD_UNCOM: null,
            _rCD_DEPTO: null,
            _rCD_SECAO: null,
            _rCD_OPESIS: usuario.base.us01_nr_usr,
          };
          this.gruposSelecionados.push(obj);
        }
      });
      if (this.gruposSelecionados.length !== 0) {
        try {
          await this.postSP("SP_CE360901018", this.gruposSelecionados);
          this.notification().success({mensagem:"Informações salvas com sucesso"});
        } catch (error) {
          this.notification().error({erro: "Não foi possivel salvar as informações"});
        } finally {
          this.mountObj();
        }
      } else {
        this.notification().warn({ mensagem: "Selecione os grupos para salvar"});
      }
    },
    apagarItens(e) {
      e.forEach((f) => {
        let index = this.dados.findIndex((item) => item == f);
        if (index !== -1) {
          this.dados.splice(index, 1);
        }
      });
      this.abas.forEach((f, i) => {
        if (f.pesquisa.length == 0) {
          this.abas.splice(i, 1);
        }
      });
    },
    recursive(data, parent = null) {
      return data.reduce((r, e) => {
        if (e.ce113_nr_grpsup == parent) {
          e.children = this.recursive(data, e.ce113_cd_grp);
          r.push(e);
        }
        return r;
      }, []);
    },
    erroBuscaPorFiltro() {
      this.notification().error({erro: "O filtro possui algum erro."});
    },
    async editNovoItem(verificador, e) {
      let usuario = await this.getUser();
      let ent = {
        _rNR_NOVO: verificador,
        _rCD_GRP: e.ce113_cd_grp,
        _rDS_HIER:
          verificador == 1
            ? e.ce113_ds_grpex !== null
              ? `${e.ce113_ds_grpex}>${e.ce113_ds_grp}`
              : e.ce113_ds_grp
            : e.ce113_ds_grpex,
        _rNR_NIVEL: 1,
        _rCD_GRPSUP: e.ce113_cd_grpsup == "" ? null : e.ce113_cd_grpsup,
        _rDS_GRP: e.ce113_ds_grp,
        _rCD_SITUAC: e.ce113_cd_situac == "" ? null : e.ce113_cd_situac,
        _rID_EXIBLJ: e.ce113_id_exiblj == "" ? null : e.ce113_id_exiblj,
        _rCD_NCM: e.ce113_cd_nps == "" ? null : e.ce113_cd_nps,
        _rCD_EXNCM: e.ce113_cd_exncm == "" ? null : e.ce113_cd_exncm,
        _rCD_CLASS: null,
        _rCD_UNCOM: null,
        _rCD_DEPTO: null,
        _rCD_SECAO: null,
        _rCD_OPESIS: usuario.base.us01_nr_usr,
      };
      try {
        await this.request({
          metodo: "get",
          sp: "SP_CE360901018",
          params: ent,
        });
        this.notification().success({mensagem:"Informações salvas com sucesso"});
      } catch (error) {
        this.notification().error({erro: "Não foi possivel salvar as informações"});
      } finally {
        this.mountObj();
      }
    },
    /* eslint-disable */
    salvaDadosTabela() {
      //
    },
    dadosConfirmados(dadosCorretos, dadosIncorretos, nomeArquivo) {
      //
    },
    /* eslint-disable */
  },
};
