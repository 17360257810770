/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: colunas.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:03
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:43:39
 Modificado por: pedro.rosa
*/
module.exports = {
  camposEditarProduto: [
    {
      DIVISION: "Grupo",
      fields: [
        {
          model: "",
          vuetify: "textfield",
          type: "text", // principais: text, tel, password
          nome: "Hierarquia",
          hint: "", // opcional
          cols: 8,
          required: true,
          rules: [(n) => !!n || "Campo obrigatório"],
          chave: "ce113_ds_grpex",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text", // principais: text, tel, password
          nome: "Grupo superior",
          hint: "", // opcional
          cols: 4,
          maxLength: 5,
          chave: "ce113_cd_grpsup",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text", // principais: text, tel, password
          nome: "Descrição",
          hint: "", // opcional
          cols: 12,
          maxLength: 40,
          required: true,
          rules: [(n) => !!n || "Campo obrigatório"],
          chave: "ce113_ds_grp",
        },
        {
          model: "",
          vuetify: "autocomplete",
          type: "text", // principais: text, tel, password
          nome: "Situação",
          hint: "", // opcional
          cols: 6,
          items: [
            { text: "Ativo", value: 1 },
            { text: "Inativo", value: 2 },
          ],
          required: true,
          rules: [(n) => !!n || "Campo obrigatório"],
          chave: "ce113_cd_situac",
        },
        {
          model: "",
          vuetify: "autocomplete",
          type: "text", // principais: text, tel, password
          nome: "Visivel para venda",
          hint: "", // opcional
          cols: 6,
          required: true,
          items: [
            { text: "Sim", value: 1 },
            { text: "Não", value: 2 },
          ],
          rules: [(n) => !!n || "Campo obrigatório"],
          chave: "ce113_id_exiblj",
        },
        {
          model: "",
          vuetify: "searchtype",
          type: "text", // principais: text, tel, password
          nome: "NCM",
          sp: "SP_CE360903010",
          filtro: true,
          adicionar: false,
          opcoesTipo: [
            {
              value: "NF730_CD_NPS",
              text: "Cd. NCM",
              type: ["text"],
            },
            {
              value: "NF730_DS_NPS",
              text: "Ds. NCM",
              type: ["text"],
            },
          ],
          retorno: [],
          hint: "", // opcional
          cols: 8,
          chave: "ce113_cd_nps",
        },
        {
          model: "",
          vuetify: "searchtype",
          type: "text", // principais: text, tel, password
          nome: "Exeção NCM",
          hint: "", // opcional
          cols: 4,
          chave: "ce110_cd_exncm",
        },
      ],
    },
  ],
  opcoesTipo: [
    {
      value: "CE113_CD_GRP",
      text: "Cd. grp",
      type: ["text"],
    },
    {
      value: "CE113_DS_GRP",
      text: "Ds. grp",
      type: ["text"],
      mask: "XX.XXX.XXX/XXXX-XX",
    },
    {
      value: "CE113_DS_HIER",
      text: "Ds. hier",
      type: ["number"],
    },
  ],
  itensFerramentas: [
    {
      text: "Situação",
      value: {
        type: "autocomplete",
        SP: false,
        opcoesTipo: "ce113_cd_situac",
        nome: "situacao",
        adicionar: false,
        filtro: false,
        itens: [
          { text: "Ativo", value: 1 },
          { text: "Bloqueado", value: 2 },
        ],
        objKey: false,
      },
    },
    {
      text: "Visivel p/ venda",
      value: {
        type: "autocomplete",
        SP: false,
        opcoesTipo: "ce113_id_exiblj",
        nome: "visiVenda",
        adicionar: false,
        filtro: false,
        itens: [
          { text: "Sim", value: 1 },
          { text: "Não", value: 2 },
        ],
        objKey: false,
      },
    },
  ],
  colunasExcel: [
    {
      COMISSÃO: "",
      "SALÁRIO FIXO": "",
      VENDEDOR: "",
    },
  ],
  colunas: [
    {
      cabecalho: "Nm. grupo",
      larg: "1*",
      valor: "ce113_ds_grp",
    },
    {
      cabecalho: "Nm. grupo",
      larg: "1*",
      valor: "ce113_ds_grp",
    },
    {
      cabecalho: "Hierarquia",
      larg: "2*",
      valor: "ce113_ds_grpex",
      align: "left",
    },
    {
      cabecalho: "Situação",
      larg: "0.5*",
      valor: "ce113_cd_situacex",
    },
    {
      cabecalho: "Visivel p/ venda",
      larg: "0.5*",
      valor: "ce113_id_exibljex",
    },
  ],
};
