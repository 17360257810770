/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: script.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:03
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:43:22
 Modificado por: pedro.rosa
*/
import ferramentas from "@/components/ferramentas";
import botoesAcao from "@/components/botoesAcao";
import {
  camposEditarProduto,
  opcoesTipo,
  itensFerramentas,
  colunasExcel,
  colunas,
} from "./colunas";
import moment from "moment";
export default {
  components: { ferramentas, botoesAcao },
  props: {
    escolha: {
      type: String,
      required: true,
    },
    prop: {
      click: {
        type: Number,
        default: 0,
        required: true,
      },
    },
  },
  data() {
    return {
      flexgrid: {},
      nrEscolha: 0,
      opcoesTipo: opcoesTipo,
      abaEscolhida: "",
      keyTabelaWijmo: 0,
      itensFerramentas: itensFerramentas,
      colunasExcel: colunasExcel,
      colunas: colunas,
      camposEditar: [],
      dados: [],
      abas: [],
      botoes: [],
      importsObrigatorios: ["COMISSÃO", "SALÁRIO FIXO", "VENDEDOR"],
      tabelaImportada: [
        {
          cabecalho: "Descrição",
          larg: "2.5*",
          valor: "DESCRIÇÃO",
          align: "left",
        },
      ],
      camposEditarProduto: camposEditarProduto,
    };
  },
  computed: {
    camposCEP() {
      return this.camposEditarProduto[1].fields[0];
    },
  },
  watch: {
    escolha() {
      this.mountObj();
    },
    click() {
      this.mountObj();
    },
  },
  mounted() {
    this.mountObj();
  },
  methods: {
    deletar(e) {
      e.forEach((f) => {
        let index = this.dados.findIndex((item) => item == f);
        this.dados.splice(index, 1);
      });
      this.keyTabelaWijmo++;
    },
    /* eslint-disable */
    addNovoItem(item) {
      //
    },
    /* eslint-disable */
    initializeGrid(flexgrid) {
      this.flexgrid = flexgrid;
    },
    escolheAba(aba) {
      this.abaEscolhida = aba;
    },
    alteraVendFerramentas(ret) {
      this.dados.forEach((f) => {
        if (f.isSelected) {
          f.descricao = ret.descricao;
        }
      });
      this.keyTabelaWijmo++;
    },
    async mountObj() {
      this.startLoader();
      switch (this.escolha) {
        case "Seção":
          this.botoes = [
            "EXPORTAR_EXCEL",
            "EXPORTAR_PDF",
            "EDITAR",
            "SALVAR",
            "NOVO",
          ];
          this.nrEscolha = 1;
          break;
        case "Departamento":
          this.botoes = [
            "EXPORTAR_EXCEL",
            "EXPORTAR_PDF",
            "EDITAR",
            "SALVAR",
            "NOVO",
          ];
          this.nrEscolha = 2;
          break;
        case "Coleção":
          this.botoes = [
            "EXPORTAR_EXCEL",
            "EXPORTAR_PDF",
            "EDITAR",
            "SALVAR",
            "NOVO",
          ];
          this.nrEscolha = 3;
          break;
        case "Categoria":
          this.botoes = [
            "EXPORTAR_EXCEL",
            "EXPORTAR_PDF",
            "EDITAR",
            "SALVAR",
            "NOVO",
          ];
          this.nrEscolha = 4;
          break;
        case "Cor":
          this.botoes = [
            "EXPORTAR_EXCEL",
            "EXPORTAR_PDF",
            "EDITAR",
            "SALVAR",
            "NOVO",
          ];
          this.nrEscolha = 5;
          break;
        case "Tamanho":
          this.botoes = [
            "EXPORTAR_EXCEL",
            "EXPORTAR_PDF",
            "EDITAR",
            "SALVAR",
            "NOVO",
          ];
          this.nrEscolha = 6;
          break;
        case "Marca":
          this.botoes = [
            "EXPORTAR_EXCEL",
            "EXPORTAR_PDF",
            "EDITAR",
            "SALVAR",
            "NOVO",
          ];
          this.nrEscolha = 7;
          break;
        case "Fabricantes":
          this.botoes = ["EXPORTAR_EXCEL", "EXPORTAR_PDF", "EDITAR", "SALVAR"];
          this.nrEscolha = 8;
          break;
      }
      try {
        let itemsDB = await this.request({
          metodo: "get",
          sp: "SP_CE360901026",
          params: { _rVERIFICADOR: this.nrEscolha },
        });
        itemsDB = itemsDB.database;
        this.dados = itemsDB.map((f) => {
          return {
            ...f,
            isSelected: false,
          };
        });
        const objAba = {
          nome_aba: `${this.$t("TelaLojas.pesquisa")} ${this.abas.length +
            1} - ${moment()
            .locale("pt-br")
            .format("l")} - ${this.$t("TelaLojas.quantidade")}: ${
            this.dados.length
          }`,
          pesquisa: this.dados,
          remove: false,
        };
        this.abas.push(objAba);
      } catch (err) {
        this.notification().error({erro: "Erro ao trazer as informações"});
      } finally {
        this.stopLoader();
      }
    },
    erroBuscaPorFiltro() {
      this.notification().error({erro: "O filtro possui algum erro."});
    },
    geraCod() {
      let valorAnterior = 0;
      this.dados.forEach((f) => {
        if (f.codigo > valorAnterior) {
          valorAnterior = f.codigo;
        }
      });
      return valorAnterior + 1;
    },
    async salvaLinha() {
      let usuario = await this.getUser();
      let ent = [];
      ent = this.dados.map((f) => {
        if (f.isSelected) {
          return {
            _rTABELA: this.nrEscolha,
            _rNOVO: f._rNOVO ? 1 : 2,
            _rCD_ESCOLHIDO:
              !f.codigo || f.codigo == "" ? this.geraCod() : f.codigo,
            _rDS_ESCOLHIDO: f.descricao,
            _rCD_OPESIS: usuario.base.us01_nr_usr,
          };
        }
      });
      try {
        this.startLoader();
        await this.postSP("SP_CE360901019", ent);
        this.notification().success({mensagem:"Salvo com sucesso!"});
      } catch (err) {
        this.notification().error({erro: "Não foi possível salvar as informações"});
      } finally {
        this.stopLoader();
      }
    },
    async editNovoItem(e, novo) {
      let usuario = await this.getUser();
      let ent = {
        _rTABELA: this.nrEscolha,
        _rNOVO: novo,
        _rCD_ESCOLHIDO: !e.codigo || e.codigo == "" ? this.geraCod() : e.codigo,
        _rDS_ESCOLHIDO: e.descricao,
        _rCD_OPESIS: usuario.base.us01_nr_usr,
      };
      try {
        this.request({
          metodo: "get",
          sp: "SP_CE360901019",
          params: ent,
        });
        this.notification().success({mensagem:`${this.escolha} salvo com sucesso`});
      } catch (err) {
        this.notification().error({erro: err});
      } finally {
        setTimeout(() => {
          this.mountObj();
        }, 300);
      }
    },
    dadosConfirmados(dadosCorretos, dadosIncorretos, nomeArquivo) {
      dadosCorretos = dadosCorretos.map((f, i) => {
        return {
          codigo: this.geraCod() + i,
          descricao: f.DESCRIÇÃO,
          _rNOVO: true,
        };
      });
      if (dadosCorretos.length > 0) {
        let objAba = {
          nome_aba: `Dados corretos - ${nomeArquivo} - Quantidade: ${dadosCorretos.length}`,
          pesquisa: dadosCorretos,
          remove: false,
        };
        this.abas.push(objAba);
      }
      if (dadosIncorretos.length > 0) {
        this.notification().warn({ mensagem: `Um total de ${dadosIncorretos.length} encontrados`});
      }
    },
  },
};
