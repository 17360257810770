/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: colunas.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:03
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:43:16
 Modificado por: pedro.rosa
*/
module.exports = {
  camposEditarProduto: [
    {
      DIVISION: "",
      fields: [
        {
          model: "",
          vuetify: "textfield",
          type: "text", // principais: text, tel, password
          nome: "Código",
          hint: "", // opcional
          cols: 2,
          required: false,
          disabled: true,
          chave: "codigo",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text", // principais: text, tel, password
          nome: "Descrição",
          hint: "", // opcional
          cols: 10,
          maxLength: 40,
          required: true,
          rules: [(n) => !!n || "Campo obrigatório"],
          chave: "descricao",
        },
      ],
    },
  ],
  opcoesTipo: [
    {
      value: "CE045_NM_RAZAO",
      text: "Razão social",
      type: ["text"],
    },
    {
      value: "CE045_NR_CNPJ",
      text: "CNPJ",
      type: ["text"],
      mask: "XX.XXX.XXX/XXXX-XX",
    },
    {
      value: "CE045_NR_LOJA",
      text: "Nr. loja",
      type: ["number"],
    },
    {
      value: "CE045_NR_CEP",
      text: "CEP",
      type: ["number"],
      mask: "XXXXX-XX",
    },
  ],
  itensFerramentas: [
    {
      text: "Descrição",
      value: {
        type: "textfield",
        SP: false,
        opcoesTipo: "ce110_cd_situac",
        nome: "descricao",
        adicionar: false,
        filtro: false,
        objKey: false,
      },
    },
  ],
  colunasExcel: [
    {
      DESCRIÇÃO: "",
    },
  ],
  colunas: [
    {
      cabecalho: "Descrição",
      larg: "10*",
      valor: "descricao",
      align: "left",
    },
  ],
};
